import React from 'react'
import { useMediaQuery } from '@mui/material'
import * as classes from '../../styles/HowItWorkSection.module.scss'
import InfoCard from './InfoCard'
import imgsrc from '../../images/whywe.svg'
import section1img from "../../images/hiw_section1.svg";

export default function HowItWorkSection() {

  return (
   <>
    <InfoCard 
        title="How It Works?"
        infoText={
          <>
            Dive into our streamlined process where experts, like you, can transform professional connections into successful recommendations. Here's a glimpse of how it unfolds:
            <ul className={classes.stepList}>
              <li>Discover Opportunities: Spot openings through your network or select roles posted by employers.</li>
              <li>Recommend Talent: Suggest the most suitable candidates using your insights.</li>
              <li>Earn Rewards: Receive financial rewards for every successful placement.</li>
            </ul>
          </> 
        }       
        img={section1img} 
      />

      <InfoCard 
        title="Who Is It For?"
        infoText={
          <>
            Our platform is designed for professionals who want to leverage their expertise and connections to make a difference:
            <ul className={classes.stepList}>
              <li>You Have Industry Knowledge: Leverage your deep experience to connect the right talent with the right opportunities.</li>
              <li>You Have a Trusted Network: Tap into your professional connections to create impactful career matches.</li>
              <li>You Love Empowering Others: Shape careers by helping talented individuals grow and succeed.</li>
              <li>You’re Seeking Flexibility: Work on your own terms while earning rewards for every successful hire.</li>
            </ul>
          </> 
        }       
        img={imgsrc} 
      />      
    </>
  )
}
