// extracted by mini-css-extract-plugin
export var section1 = "expert_home-module--section1--f3bl0";
export var heroSection = "expert_home-module--heroSection--YXQT1";
export var mainText = "expert_home-module--mainText--VZQYa";
export var moreInfo = "expert_home-module--moreInfo---5x3n";
export var actions = "expert_home-module--actions--oiYS7";
export var signInWithLinkedInBut = "expert_home-module--signInWithLinkedInBut--VUlRF";
export var learnMoreBut = "expert_home-module--learnMoreBut--tW7u3";
export var m_yVideo = "expert_home-module--m_yVideo--+qPXM";
export var yVideo = "expert_home-module--yVideo--xU8Uu";
export var features = "expert_home-module--features--f5n5v";
export var expertText = "expert_home-module--expertText--2MSAc";
export var featureContainer = "expert_home-module--featureContainer--Ojy9y";
export var featureCard = "expert_home-module--featureCard--si5Q+";
export var textContent = "expert_home-module--textContent--+pQ8w";